const initialState = {
  isOpen: false
};

export const productsIsOpenReducer = (state = initialState, action) => {
  switch (action.type) {
    case "NAVBAR_PRODUCTS_IS_OPEN":
      return {
        isOpen: !state.isOpen
      }
    default:
      return state;
  }
}