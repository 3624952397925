import React, { Component } from 'react';
import './Partners.scss';

class Partners extends Component {
  render() {
    return (
      <div className="Partners">
        <div className="slider">
          <div className="slide-track">
            <div className="slide"><img src="/assets/img/partners/april.jpg" alt="partner-logo" className="partner" /></div>
            <div className="slide"><img src="/assets/img/partners/adf.jpg" alt="partner-logo" className="partner" /></div>
            <div className="slide"><img src="/assets/img/partners/malakoff.jpg" alt="partner-logo" className="partner" /></div>
            <div className="slide"><img src="/assets/img/partners/netvox.jpg" alt="partner-logo" className="partner" /></div>
            <div className="slide"><img src="/assets/img/partners/sollyazar.jpg" alt="partner-logo" className="partner" /></div>
            <div className="slide"><img src="/assets/img/partners/tetris.jpg" alt="partner-logo" className="partner" /></div>
            <div className="slide"><img src="/assets/img/partners/simple.jpg" alt="partner-logo" className="partner" /></div>
            <div className="slide"><img src="/assets/img/partners/april.jpg" alt="partner-logo" className="partner" /></div>
            <div className="slide"><img src="/assets/img/partners/adf.jpg" alt="partner-logo" className="partner" /></div>
            <div className="slide"><img src="/assets/img/partners/malakoff.jpg" alt="partner-logo" className="partner" /></div>
            <div className="slide"><img src="/assets/img/partners/netvox.jpg" alt="partner-logo" className="partner" /></div>
            <div className="slide"><img src="/assets/img/partners/sollyazar.jpg" alt="partner-logo" className="partner" /></div>
            <div className="slide"><img src="/assets/img/partners/tetris.jpg" alt="partner-logo" className="partner" /></div>
            <div className="slide"><img src="/assets/img/partners/simple.jpg" alt="partner-logo" className="partner" /></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Partners;
