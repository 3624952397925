import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { navbarIsOpen } from '../../../actions/navbarAction';
import { navbarProductsIsOpen } from '../../../actions/navbarOpenAction';

import NavOpen from './NavOpen';
import './Navbar.scss';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollBottom: true
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("size", this.nav());
  }
  nav = () => {
    const navbarForm = document.getElementById("navbarForm");
    const navOpen = document.getElementById("navItems");
    if (window.innerWidth >= 1200) {
      navOpen.style.display = "flex";
      navbarForm.style.display = "flex";
    }
  }

  handleNavbar = () => {
    window.scrollTo(0, 0);
    const navbarForm = document.getElementById("navbarForm");
    const navOpen = document.getElementById("navItems");
    this.props.navbarIsOpen(!this.props.isOpen);
    if (!this.props.isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
    if (this.props.productsIsOpen) {
      setTimeout(() => {
        this.props.navbarProductsIsOpen(false);
      }, 400);
    }

    if (window.innerWidth <= 1200 && !this.props.isOpen) {
      navOpen.style.display = "flex";
      navbarForm.style.display = "none";
    }
    if (window.innerWidth >= 1200) {
      navOpen.style.display = "flex";
      navbarForm.style.display = "flex";
    }
  }

  onScroll = () => {
    if (window.scrollY >= 400) {
      document.getElementById("arrow").style.transform = "rotate(180deg)";
      this.setState({ scrollBottom: false })
    }
    else {
      document.getElementById("arrow").style.transform = "rotate(0deg)";
      this.setState({ scrollBottom: true })
    }
  }

  scrollTo = () => {
    if (this.state.scrollBottom === true) {
      const top = document.getElementById("insurance").offsetTop;
      window.scrollTo({top: top - 100, left: 0, behavior: 'smooth' });
    } else {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }
  }

  render() {
    let nav = "Nav";
    let menu = "menu";
    let darkBg = "dark-bg";
    if (this.props.isOpen) {
      nav += " active";
      menu += " active";
      darkBg += " active";
    }
    return (
      <div className="Navbar">
        <div className={darkBg}></div>
        <div className="left-bar">
          <img className="logo" src="/assets/svg/mrswiss.svg" alt="mrswiss-logo" />
          <div className="social-network">
            <div className="link">
              <a href="https://www.facebook.com/mrswissedgar/" target="_blank" rel="noreferrer">
                <img src="/assets/svg/contact/facebook.svg" alt="facebook-icon" />
              </a>
              <a href="https://www.instagram.com/mr_swiss_edgar_melikyan_/" target="_blank" rel="noreferrer">
                <img src="/assets/svg/contact/instagram.svg" alt="instagram-icon" />
              </a>
            </div>
            <div className="line"></div>
            <p className="text"><span className="text-color">#</span>SUIVEZ-NOUS</p>
          </div>
          <div id="arrow" className="arrow" onClick={this.scrollTo}> 
            <svg version="1.1" fill="#dc1c00" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 213.333 213.333">
              <polygon points="0,53.333 106.667,160 213.333,53.333 		"/>
            </svg>
          </div>
        </div>
        <div className="top-bar">
          <div className="name"><p className="text">Mr Swiss</p></div>
          <div className="text-container">
            <p className="text">Votre comparateur d'assurance en ligne<span className="dot"></span></p>
          </div>
        </div>
        <div className="corner">
          <div className={menu} onClick={this.handleNavbar}>
            <span className="stroke"></span>
            <span className="stroke"></span>
            <span className="stroke"></span>
          </div>
          <svg className="corner-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
            <path d="M1339.00208,1509.91931c-.00074.05392-.00208.10735-.00208.16127,0,137.9821,111.92883,249.83873,250,249.83873v-250Z" transform="translate(-1339 -1509.91931)"/>
          </svg>
          <svg className="corner-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
            <path d="M1339.00208,1509.91931c-.00074.05392-.00208.10735-.00208.16127,0,137.9821,111.92883,249.83873,250,249.83873v-250Z" transform="translate(-1339 -1509.91931)"/>
          </svg>
        </div>
        <NavOpen nav={nav} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isOpen: state.isOpenReducer.isOpen,
    productsIsOpen: state.productsIsOpenReducer.isOpen,
  }
}

const mapDispatchToProps = (dispatch) => ({
  navbarIsOpen: bindActionCreators(navbarIsOpen, dispatch),
  navbarProductsIsOpen: bindActionCreators(navbarProductsIsOpen, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
