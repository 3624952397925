import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { navbarIsOpen } from '../../../actions/navbarAction';

import NavbarForm from '../../components/navbar/NavbarForm';
import './NavOpen.scss';

class NavOpen extends Component {
  handleNavbar = () => {
    this.props.navbarIsOpen(false);
    document.body.style.overflow = "visible";
  }
  render() {
    return (
      <div className={this.props.nav}>
        <NavbarForm />
        <div id="navItems" className="nav-items">
          <nav>
            <a className="item" href="/" onClick={this.handleNavbar}>Accueil</a>
            <a className="item" href="/#insurance" onClick={this.handleNavbar}>Nos assurances</a>
            <a className="item" href="/#company" onClick={this.handleNavbar}>Société</a>
            <a className="item" href="/#contact" onClick={this.handleNavbar}>Contact</a>
            
            <div className="contact-container">
              <div className="contact">
                <a href="tel:06-86-74-33-13"><span className="text-weigth">Tel : </span>+33 6 86 74 33 13</a>
                <a href="mailto:mrswiss@outlook.fr"><span className="text-weigth">Email : </span>mrswiss@outlook.fr</a>
              </div>
              <div className="social-network">
                <a name="facebook" aria-label="facebook" href="https://www.facebook.com/mrswissedgar/" target="_blank" rel="noopener noreferrer">
                  <img className="network" src="/assets/svg/contact/facebook.svg" alt="facebook-icon" />
                </a>
                <a name="instagram" aria-label="instagram" href="https://www.instagram.com/mr_swiss_edgar_melikyan_/" target="_blank" rel="noopener noreferrer">
                  <img className="network" src="/assets/svg/contact/instagram.svg" alt="instagram-icon" />
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isOpen: state.isOpenReducer.isOpen,
  }
}

const mapDispatchToProps = (dispatch) => ({
  navbarIsOpen: bindActionCreators(navbarIsOpen, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(NavOpen);

