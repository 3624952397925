import React, { Component } from 'react';
import './Footer.scss';

class Footer extends Component {
  render() {
    return (
      <div className="Footer">
        <div className="contact-container">
          <a className="link phone" href="tel:06-86-74-33-13">
            <img src="/assets/svg/contact/whatsapp.svg" alt="telephone-icon" className="icon telephone"/>
            <p className="text">06 86 74 33 13</p>
          </a>
          <a className="link email" href="mailto:mrswiss@outlook.fr">
            <img src="/assets/svg/contact/messager.svg" alt="email-icon" className="icon email"/>
            <p className="text">mrswiss@outlook.fr</p>
          </a>
        </div>
        <div className="footer-container">
          <div className="mr-swiss">
            <div className="logo-container">
              <img className="logo" src="/assets/svg/mrswiss-white.svg" alt="logo-mr-swiss" />
              <span className="name">Mr Swiss</span>
            </div>

            <p className="address">
              <span className="title">Coordonnées :</span>
              <a 
                href="https://www.google.com/maps?q=44+Cours+de+Qu%C3%A9bec,+33300+Bordeaux&ftid=0xd552850f892bf5d:0x9af0c786f4471add"
                target="_blank"
                rel="noopener noreferrer"
              >
                44 bis cours du Québec <br />
                33300 Bordeaux - France <br />
              </a>
            </p>
          </div>
          <div className="informations">
            <p className="contact">
              <span className="title">Contact :</span>
              <a href="tel:06-86-74-33-13">Tel : +33 (0)6 86 74 33 13</a> <br />
              <a href="mailto:mrswiss@outlook.fr">Email : mrswiss@outlook.fr</a>
            </p>
            <p className="ouverture">
              <span className="title">Ouverture :</span>
              <a href="https://g.page/mr-swiss?share" target="_blank" rel="noreferrer">
                Du lundi au samedi 07h00 à 22h00.
              </a>
            </p>
          </div>
          <div className="sections">
            <span className="title">Plan du site :</span>
            <div className="category">
              <div className="section">
                <a to="/" href="/#">Accueil</a>
                <a to="/" href="/#insurance">Nos assurances</a>
                <a to="/" href="/#company">Société</a>
                <a to="/" href="/#contact">Contact</a>
                <a to="/" href="mailto:mrswiss@outlook.fr">Demande de résiliation</a>
                <a to="/" href="/assets/mrswiss-legal-notice.pdf">Mentions légales</a>              
              </div>
              <div className="social-network">
                <a name="facebook" aria-label="facebook" href="https://www.facebook.com/mrswissedgar/" target="_blank" rel="noopener noreferrer">
                  <img className="network" src="/assets/svg/contact/facebook-white.svg" alt="facebook-icon" />
                </a>
                <a name="instagram" aria-label="instagram" href="https://www.instagram.com/mr_swiss_edgar_melikyan_/" target="_blank" rel="noopener noreferrer">
                  <img className="network" src="/assets/svg/contact/instagram-white.svg" alt="instagram-icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
