import React, { Component } from 'react';
import { dataInsurance } from '../../../data/dataInsurance';
import './Insurance.scss';

class Insurance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      isOpen: false
    }
  }

  handleInsurance = (e) => {
    this.setState({ isOpen: !this.state.isOpen, id: e.target.id });
    if (!this.state.isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }

  render() {
    let darkBg = "dark-bg"
    let modal = "modal"
    if (this.state.isOpen) {
      darkBg += " active";
      modal += " active";

    }



    return (
      <div id="insurance" className="Insurance">
        <h2>Nos assurances</h2>
        <div className="insurance-container">
          {
            dataInsurance.map((insurance, i) =>
            <div className="product" key={i}>
              
              <img src={`/assets/svg/insurance/${insurance.icon}.svg`} alt="insurance-icon" className="icon"/>
              <p className="name">{insurance.title}</p>
              {
                (insurance.devis_href !== "")
                ?
                <a href={insurance.devis_href} target="_blank" rel="noreferrer" className="button"><span className="arrow">⇒</span>devis en ligne</a>
                :
                <div id={insurance.id} className="button" onClick={this.handleInsurance}><span className="arrow">⇒</span>en savoir plus</div>
              }

            </div>
            )
          }
        </div>
        <div id="insuranceDarkBg" className={darkBg}></div>
        <div className={modal}>
          {
            dataInsurance.filter(insurance => insurance.id === parseInt(this.state.id)).map((insurance, i) => 
              <div className="container" key={i}>
                <img src={`/assets/svg/insurance/${insurance.icon}.svg`} alt="insurance-icon" />
                <div className="close" onClick={this.handleInsurance}>x</div>
                <div className="modal-content">
                  <p className="modal-name">{insurance.title}</p>
                  <div className="link-container">
                    <a href={insurance.devis_href_1} target="_blank" rel="noreferrer">{(insurance.id === 1) ? "Auto" : (insurance.id === 2) ? "Flotte Auto" : (insurance.id === 12) ? "Assurance Bateau" : "Mutuelle"}</a>
                    <a href={insurance.devis_href_2} target="_blank" rel="noreferrer">{(insurance.id === 1) ? "Auto VTC" : (insurance.id === 2) ? "Auto Taxi" : (insurance.id === 12) ? "LOA & Crédit simulation" : "Prévoyance"}</a>
                  </div>
                </div>
              </div>
            )
          }
        </div>        


      </div>
    );
  }
}

export default Insurance;
