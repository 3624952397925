export const dataInsurance = [
  {
    id: 1,
    icon: 'auto',
    title: 'Auto & VTC',
    href: 'auto',
    devis_href: '',
    devis_href_1: 'http://www.gestion-assurances.com/site_partenaire.aspx?code=OPE18543&dst=2',
    devis_href_2: 'https://mrswiss.plussimple.fr/assurances/auto-chauffeur',
    isPro: 1
  },
  {
    id: 2,
    icon: 'flotte-auto',
    title: 'Garage Pro',
    href: 'garage-pro',
    devis_href: 'https://mrswiss.plussimple.fr/assurances/garage-pro',
    // devis_href_2: 'https://mrswiss.plussimple.fr/assurances/auto-taxi',
    isPro: 0
  },
  {
    id: 3,
    icon: 'agent-immo',
    title: 'RC Pro Agent Immobilier',
    href: 'agent-immo',
    devis_href: 'https://mrswiss.plussimple.fr/assurances/rcpro-agent-immobilier',
    isPro: 0
  },
  {
    id: 4,
    icon: 'moto',
    title: 'Moto',
    href: 'moto',
    devis_href: 'http://moto.sollyazarpro.com/produit/motos/?i=12f4bed1f647cf9a6a31e40d9362a364047e902c',
    isPro: 0
  },
  {
    id: 5,
    icon: 'habitation',
    title: 'Habitation',
    href: 'habitation',
    devis_href: 'http://www.gestion-assurances.com/site_partenaire.aspx?code=OPE18543&dst=12',
    isPro: 0
  },
  {
    id: 6,
    name: "mutuelle",
    icon: 'mutuelle',
    title: 'Mutuelle & Prévoyance',
    href: 'mutuelle',
    devis_href: '',
    devis_href_1: 'https://tarif-complementaire-sante.april.fr/?LI=AD1CFE9B-1644-4EA1-AC2B-A45C61F7F305&CO=99603',
    devis_href_2: 'https://mrswiss.plussimple.fr/assurances/prevoyance-accident-maladie',
    // devis_href_3: 'https://tarif-complementaire-sante.april.fr/?LI=AD1CFE9B-1644-4EA1-AC2B-A45C61F7F305&CO=99603',
    isPro: 1
  },
  // {
  //   id: 7,
  //   icon: 'voyage',
  //   title: 'Voyages & Mutuelle',
  //   href: 'voyage-mutuelle-internationale',
  //   devis_href: 'https://tarif-assurance-expat.april-international.com/convergence/?LI=C5C233D1-A061-46A4-A45C-DF323DF1C3FB&Produit=MHIV1&Marque=APRIL&ContexteLayout=GPAIE&langue=fr-FR&AON_ID=MXJqdytUYVR6dU5jb3FNWnlUM2ZJUT09&CO=I84000@APMOB#?sid=ea5a03d9-3867-4775-876d-3e8f1b948fbe',
  //   isPro: 0
  // },
  // {
  //   id: 8,
  //   icon: 'fas fa-heart-broken',
  //   title: 'Obsèques',
  //   href: 'obseques',
  //   devis_href: '',
  //   isPro: 0
  // },
  {
    id: 9,
    icon: 'loyer',
    title: 'Loyers Impayés',
    href: 'loyers-impayes',
    devis_href: 'http://gli2i.sollyazarpro.com/produit/gli2i/?i=12f4bed1f647cf9a6a31e40d9362a364047e902c',
    isPro: 0
  },
  {
    id: 10,
    icon: 'pret',
    title: 'Assurance prêt',
    href: 'emprunteur',
    devis_href: 'https://tarif-assurance-pret-immobilier.april.fr/?LI=AD1CFE9B-1644-4EA1-AC2B-A45C61F7F305&CO=99603',
    isPro: 0
  },
  // {
  //   id: 11,
  //   icon: 'animaux',
  //   title: 'Mutuelle Chiens & Chats',
  //   href: 'mutuelle-chien-chat',
  //   devis_href: 'https://tarif-assurance-sante-chiens-chats.april.fr/#/?LI=AD1CFE9B-1644-4EA1-AC2B-A45C61F7F305&CO=99603',
  //   isPro: 0
  // },
  {
    id: 12,
    name: "bateau",
    icon: 'bateau',
    title: 'Bateau & Financement',
    href: 'bateau-de-plaisance-et-financement',
    devis_href: '',
    devis_href_1: 'https://espaceprive.aprilmarine.fr/prg/iframe.php?iframe=1&code_ecran=ADV036&partenaire=MRS620',
    devis_href_2: 'https://espaceprive.aprilmarine.fr/prg/iframe.php?iframe=1&code_ecran=AFI036&partenaire=MRS620',
    isPro: 0
  },

  {
    id: 13,
    icon: 'international',
    title: 'Internationale',
    href: 'internationale',
    devis_href: 'https://tarif-assurance-expat.april-international.com/convergence/?LI=C5C233D1-A061-46A4-A45C-DF323DF1C3FB&marque=APRIL&contexteLayout=COBRAND&contexteExtension=&langue=fr-FR&Produit=MCTV1&AON_ID=MXJqdytUYVR6dU5jb3FNWnlUM2ZJUT09&CO=I84000@APMOB',
    isPro: 0
  }
]