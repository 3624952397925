import React, { Component } from 'react';
import './ContactForm.scss';

class ContactForm extends Component {
  render() {
    const { firstname, lastname, phone, email, object, type, message, handleChange, handleNotification, onKeyPress, handleSubmit, titleNotification, textNotification } = this.props;
    return (
      <div className="ContactForm">
        <div id="formDarkBg" className="dark-bg"></div>
        <div id="formNotification" className="notification-container">
          <div className="notification">
            <div className="close" onClick={handleNotification}>x</div>
            {
              (titleNotification.length < 30)
              ?
              <h4 style={{ color: "#dc1c00" }}>{titleNotification}</h4>
              :
              <h4>{titleNotification}</h4>
            }
            <p>{textNotification}</p>
          </div>
        </div>


        <img src="/assets/svg/butterfly-left.svg" alt="butterfly-icon" className="butterfly-left" />
        <img src="/assets/svg/butterfly-right.svg" alt="butterfly-icon" className="butterfly-right" />

        <div className="form-container">
          <form onSubmit={handleSubmit} onKeyPress={onKeyPress}>
            <div className="container">
              <div className="input-container">
                <label htmlFor="firstname">Prénom <span className="required">*</span></label>
                <input
                  id="firstname"
                  name="firstname"
                  value={firstname.charAt(0).toUpperCase() + firstname.slice(1)}
                  type="text"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-container">
                <label htmlFor="lastname">Nom <span className="required">*</span></label>
                <input
                  id="lastname"
                  name="lastname"
                  value={lastname.toUpperCase()}
                  type="text"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="container">
              <div className="input-container">
                <label htmlFor="phone">Téléphone <span className="required">*</span></label>
                <input
                  id="phone"
                  name="phone"
                  value={phone}
                  type="tel"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-container">
                <label htmlFor="email">Email <span className="required">*</span></label>
                <input
                  id="email"
                  name="email"
                  value={email}
                  type="email"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="container">
              <div className="input-container">
                <label htmlFor="object">Objet <span className="required">*</span></label>
                <select id="object" name="object" value={object} type="text" onChange={handleChange} required style={{ backgroundImage: "url('/assets/svg/down-arrow.svg')" }}>
                  <option>Devis personnalisé</option>
                  <option>Demande de renseignements</option>
                  <option>Autre demande</option>
                </select>
              </div>
              <div className="input-container">
                <label htmlFor="type">Vous êtes</label>
                <select id="type" name="type" value={type} type="text" onChange={handleChange} style={{ backgroundImage: "url('/assets/svg/down-arrow.svg')" }}>
                  <option>Particulier</option>
                  <option>Professionnel</option>
                </select>
              </div>
            </div>
            <div className="container">
              <div className="textarea-container">
                <label htmlFor="message">Votre message</label>
                <textarea
                  id="message"
                  name="message"
                  value={message.charAt(0).toUpperCase() + message.slice(1)}
                  type="text"
                  onChange={handleChange}
                  rows={10}
                />
              </div>
            </div>
            <div className="button-container">
              <button id="formSubmit" type="submit">Soumettre</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ContactForm;
