import Navbar from './common/containers/navbar/Navbar';
import Homepage from './common/containers/homepage/Homepage';
import Insurance from './common/components/insurance/Insurance';
import Company from './common/components/company/Company';
import Partners from './common/components/partners/Partners';
import Contact from './common/components/contact/Contact';
import Footer from './common/components/footer/Footer';

import './App.scss';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Homepage />
      <Insurance />
      <Company />
      <Partners />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
