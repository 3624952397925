import React, { Component } from 'react';
import ContactForm from './ContactForm';
import { urlApi } from '../../../constant';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
      object: 'Devis personnalisé',
      type: 'Particulier',
      message: '',
      titleNotification: '',
			textNotification: '',
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleNotification = () => {
    document.getElementById("formDarkBg").style.visibility = "hidden";
    document.getElementById("formNotification").style.visibility = "hidden";
    document.body.style.overflow = "visible";
  }

  onKeyPress(event) {
		if (event.which === 13) {
			event.preventDefault();
		}
  }
  handleSubmit = (event) => {
		event.preventDefault();
		const { firstname, lastname, phone, email, object, type, message } = this.state;
		document.getElementById("formSubmit").innerHTML = "Patientez";
    document.getElementById("formSubmit").style.backgroundColor = "#2d96ff";
    document.getElementById("formSubmit").style.borderColor = "#2d96ff";
		document.getElementById("formSubmit").disabled = true;
    document.body.style.overflow = "hidden";
		fetch(`${urlApi}/send`, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				data: {
					firstname, 
          lastname, 
          phone,
					email, 
          object,
          type,
					message
				}
			},
			body: JSON.stringify(this.state)
    })
    .then((res) => {
      if (res.ok) {
        document.getElementById("formSubmit").innerHTML = "Envoyé !";
        document.getElementById("formSubmit").style.backgroundColor = "#16b58d";
        document.getElementById("formSubmit").style.borderColor = "#16b58d";
        this.setState({
          titleNotification: 'Merci de nous avoir contacté !',
          textNotification: 'Votre demande a bien été prise en compte, et sera traitée dans les meilleurs délais.'
        });
        document.getElementById("formDarkBg").style.visibility = "visible";
        document.getElementById("formNotification").style.visibility = "visible";
      } 
    })
    .catch(err => {
      if (err) {
        document.getElementById("formSubmit").innerHTML = "Erreur !";
        document.getElementById("formSubmit").style.backgroundColor = "#dc1c00";
        document.getElementById("formSubmit").style.borderColor = "#dc1c00";
        this.setState({
          titleNotification: 'Une erreur est survenue !',
          textNotification: "Nous sommes désolés, votre demande n'a pas pu aboutir en raison d'une erreur interne. Si l'erreur persiste, contactez-nous directement à l'adresse suivante : mrswiss@outlook.fr"
        });
        document.getElementById("formDarkBg").style.visibility = "visible";
        document.getElementById("formNotification").style.visibility = "visible";
      }
    });
	}


  
  render() {
    const { firstname, lastname, phone, email, object, type, message, titleNotification, textNotification } = this.state;
    return (
      <div id="contact" className="Contact">
        <ContactForm
          firstname={firstname}
          lastname={lastname}
          phone={phone}
          email={email}
          object={object}
          type={type}
          message={message}
          titleNotification={titleNotification}
          textNotification={textNotification}
          handleNotification={this.handleNotification}
          handleChange={this.handleChange}
          onKeyPress={this.onKeyPress}
          handleSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

export default Contact;
