const initialState = {
  isOpen: false
};

export const isOpenReducer = (state = initialState, action) => {
  switch (action.type) {
    case "NAVBAR_IS_OPEN":
      return {
        isOpen: !state.isOpen
      }  
    default:
      return state;
  }
}