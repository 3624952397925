import React, { Component } from 'react';
import './Company.scss';

class Company extends Component {
  constructor(props) {
    super(props);
    this.state = {
      executed: false,
      figureOne: 0,
      figureTwo: 0,
      figureThree: 0,
    }
  }
  componentDidMount() {
    this.setState({ executed: true })
    window.addEventListener("scroll", this.animationOnScroll);
  }

  componentWillUnmount() {
    this.setState({ executed: false })
  }

  animationOnScroll = () => {
    const keyFiguresContainer = document.getElementById("keyFiguresContainer");
    if (window.scrollY >= keyFiguresContainer.offsetTop - 600  && this.state.executed) {
      for (let i = 0; i <= 33; i += 1.5) {
        setTimeout(() => {
          this.setState({ figureOne: i });
        }, i * 66.6);
      }
      for (let i = 0; i <= 100; i += 5) {
        setTimeout(() => {
          this.setState({ figureTwo: i });
        }, i * 20);
      }
      for (let i = 0; i <= 500; i += 25) {
        setTimeout(() => {
          this.setState({ figureThree: i });
        }, i * 4);
      }
      this.setState({ executed: false });
    }
  }

  render() {
    return (
      <div id="company" className="Company">
        <h2>Société</h2>
        <div className="company-container">
          <p className="text">
            Notre cabinet se situe à Bordeaux, en Nouvelle Aquitaine.
            Depuis 2017, nous sommes spécialisés dans le courtage d'assurances et patrimoines, mais également dans le conseil.
            <br /> <br />
            Notre mission ? C'est d'apporter <span className="text-color">aux entreprises</span> et <span className="text-color"> aux particuliers</span> des 
            solutions personnalisées, et leur permettre de se consacrer pleinement à leur projets. 
            Nous souhaitons simplifier l'information, et vous permettre 
            l'assurance d'un choix meilleur.
            <br /> <br />
            Notre équipe est composée de professionnels indépendants, hautement qualifiés et motivés, tous experts dans leur domaine 
            (<span className="text-color">assurance et gestion de patrimoine</span>).
          </p>
          <div id="keyFiguresContainer" className="key-figures-container">
            <div className="key-figures">
              <span className="value">{Math.trunc(this.state.figureOne)}%</span>
              <p className="text">d'économies en moyenne</p>
            </div>
            <div className="key-figures">
              <span className="value">+{this.state.figureThree}</span>
              <p className="text">clients nous font confiance</p>
            </div>
            <div className="key-figures">
              <span className="value">{this.state.figureTwo}%</span>
              <p className="text">de nos clients sont gagnants</p>
            </div>
            <div className="key-figures">
              <span className="value">{this.state.figureTwo}%</span>
              <p className="text">nous recommandent</p>
            </div>
          </div>
        </div>
        <h3>Nos Partenaires</h3>
      </div>
    );
  }
}

export default Company;
