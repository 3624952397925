import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { navbarIsOpen } from '../../../actions/navbarAction';
import './Homepage.scss';

class Homepage extends Component {

  weCallYou = () => {
    window.scrollTo(0, 0);
    const navbarForm = document.getElementById("navbarForm");
    const navOpen = document.getElementById("navItems");
    if (!this.props.isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
    this.props.navbarIsOpen(true);

    if (window.innerWidth <= 1200 && !this.props.isOpen) {
      navOpen.style.display = "none";
      navbarForm.style.display = "flex";
    }
    if (window.innerWidth >= 1200) {
      navOpen.style.display = "flex";
      navbarForm.style.display = "flex";
    }
  }
  
  render() {
    return (
      <div className="Homepage">
        <div className="homepage-container">
          <h1>Vivez en toute assurance</h1>
          {
            (window.innerWidth > 800)
            ?
            <h2>Courtier assureur digital au service des particuliers et des professionnels </h2>
            :
            <h2>Comparateur d'assurance en ligne au service des particuliers et des professionnels </h2>
          }
          <div className="button-container">
            <div className="button" onClick={this.weCallYou}>On vous appelle</div>
            <a className="button" href="#contact">Contactez-nous</a>
          </div>
        </div>
        <a href="https://g.page/mr-swiss?share" target="_blank" rel="noreferrer" className="stars">
          <span className="text">5</span>
          <span className="icon">★</span>
        </a>
        <div className="scroll"></div>
        <img className="family" src="/assets/svg/family.svg" alt="family" />
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    isOpen: state.isOpenReducer.isOpen,
    productsIsOpen: state.productsIsOpenReducer.isOpen,
  }
}

const mapDispatchToProps = (dispatch) => ({
  navbarIsOpen: bindActionCreators(navbarIsOpen, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
