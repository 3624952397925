  
import { combineReducers } from 'redux';
import { isOpenReducer } from './navbarReducer';
import { productsIsOpenReducer } from './navbarOpenReducer';

const allReducers = combineReducers({
  isOpenReducer,
  productsIsOpenReducer
});

export default allReducers;