import React, { Component } from 'react';
import { urlApi } from '../../../constant';

import './NavbarForm.scss';

class NavbarForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formFirstname: "",
      formLastname: "",
      formPhone: "",
      formObject: "",
      formDate: "",
      formMoment: "Matinée",
      titleNotification: "",
      textNotification: ""
    }
  }
  handleChange = (event) => this.setState({ [event.target.name]: event.target.value });
  onKeyPress = (event) =>  (event.which === 13) ? event.preventDefault() : "";

  handleNotification = () => {
    document.getElementById("formContainer").style.filter = "blur(0)";
    document.getElementById("navNotification").style.visibility = "hidden";
  }

  handleSubmit = (event) => {
		event.preventDefault();
		const { formFirstname, formLastname, formPhone, formObject, formDate, formMoment } = this.state;
		document.getElementById("submit").innerHTML = "Patientez";
    document.getElementById("submit").style.backgroundColor = "#2d96ff";
    document.getElementById("submit").style.borderColor = "#2d96ff";
		document.getElementById("submit").disabled = true;
		fetch(`${urlApi}/call`, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				data: {
					formFirstname, 
          formLastname, 
          formPhone,
          formObject,
          formDate,
          formMoment
				}
			},
			body: JSON.stringify(this.state)
    })
    .then((res) => {
      if (res.ok) {
        document.getElementById("submit").innerHTML = "Envoyé !";
        document.getElementById("submit").style.backgroundColor = "#16b58d";
        document.getElementById("submit").style.borderColor = "#16b58d";
        this.setState({
          titleNotification: 'Merci de nous avoir contacté !',
          textNotification: 'Votre demande a bien été prise en compte, et sera traitée dans les meilleurs délais.'
        });
        document.getElementById("formContainer").style.filter = "blur(40px)";
        document.getElementById("navNotification").style.visibility = "visible";
      } 
    })
    .catch(err => {
      if (err) {
        document.getElementById("submit").innerHTML = "Erreur !";
        document.getElementById("submit").style.backgroundColor = "#dc1c00";
        document.getElementById("submit").style.borderColor = "#dc1c00";

        this.setState({
          titleNotification: 'Une erreur est survenue !',
          textNotification: "Nous sommes désolés, votre demande n'a pas pu aboutir en raison d'une erreur interne. Si l'erreur persiste, contactez-nous directement à l'adresse suivante : mrswiss@outlook.fr"
        });
        document.getElementById("formContainer").style.filter = "blur(40px)";
        document.getElementById("navNotification").style.visibility = "visible";
      }
    });
	}


  render() {
    const { formFirstname, formLastname, formPhone, formObject, formDate, formMoment, titleNotification, textNotification } = this.state;

    return (
      <div id="navbarForm" className="NavbarForm">
        <div id="navDarkBg" className="dark-bg"></div>
        <div id="navNotification" className="notification-container">
          <div className="notification">
            <div className="close" onClick={this.handleNotification}>x</div>
            {
              (titleNotification.length < 30)
              ?
              <h4 style={{ color: "#dc1c00" }}>{titleNotification}</h4>
              :
              <h4>{titleNotification}</h4>
            }
            <p>{textNotification}</p>
          </div>
        </div>

          <div id="formContainer" className="form-container">
            <div className="title-container">
              <h3>On vous appelle</h3>
            </div>
            <form onSubmit={this.handleSubmit} onKeyPress={this.onKeyPress}>
              <div className="container">
                <div className="input-container">
                  <label htmlFor="formFirstname">Prénom <span className="required">*</span></label>
                  <input
                    id="formFirstname"
                    name="formFirstname"
                    value={formFirstname.charAt(0).toUpperCase() + formFirstname.slice(1)}
                    type="text"
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="input-container">
                  <label htmlFor="formLastname">Nom <span className="required">*</span></label>
                  <input
                    id="formLastname"
                    name="formLastname"
                    value={formLastname.toUpperCase()}
                    type="text"
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </div>
              <div className="container">
                <div className="input-container">
                  <label htmlFor="formPhone">Téléphone <span className="required">*</span></label>
                  <input
                    id="formPhone"
                    name="formPhone"
                    value={formPhone}
                    type="tel"
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="input-container">
                  <label htmlFor="formObject">Objet <span className="required">*</span></label>
                  <input
                    id="formObject"
                    name="formObject"
                    value={formObject}
                    type="text"
                    onChange={this.handleChange}
                    required
                  />
                </div>
              </div>
              <div className="container">
                <div className="input-container">
                  <label htmlFor="formDate">Date de l'appel <span className="required">*</span></label>
                  <input
                    id="formDate"
                    name="formDate"
                    value={formDate}
                    type="date"
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="input-container">
                  <label htmlFor="moment">Moment de la journée <span className="required">*</span></label>
                  <select id="moment" name="moment" value={formMoment} type="text" onChange={this.handleChange} required style={{ backgroundImage: "url('/assets/svg/down-arrow.svg')" }}>
                    <option>Matinée</option>
                    <option>Après-midi</option>
                    <option>Soirée</option>
                  </select>
                </div>
              </div>
              <div className="button-container">
                <button id="submit" type="submit">Soumettre</button>
              </div>
            </form>
          </div>
      </div>
    );
  }
}

export default NavbarForm;
